<template>
  <v-app v-if="pathInfo">
    <v-app-bar
        app
        color="white"
        height="60"
        dense
        class="app-bar"
    >
      <v-row class="width-per-100 height-per-100 ma-0 justify-center" >
        <div class="width-per-100 height-per-100 d-flex justify-space-between menu" :class="isIE == true ? 'isIE' : ''" >
          <div style="width: 108px;">
            <v-btn
                plain elevation="0"
                height="100%"
                min-width="100"
                to="/"
                class="pl-0 logo-btn"
                :ripple="false"
            >
              <v-img
                  alt="logo"
                  contain
                  position="left center"
                  src="../../assets/login/wanmuyun-logo.png"
                  height="30"
                  width="245"
              />
            </v-btn>
          </div>
          <div class="width-per-100 d-flex justify-center">
            <v-hover
                v-slot="{ hover }"
                v-for="(link,index) in links"
                :key="index">
              <div v-if="link.renderList" class="menu-list">
                <v-btn
                    height="100%"
                    light depressed plain
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    @mouseenter="showMenuChildren = true;"
                    class="px-0 ml-16"
                    @click.stop="handleMenuClick(link)"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                  <div v-if="link.children.length > 0" style="height: 30px;padding-top: 4px;">
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>
                  </div>
                </v-btn>
                <div class="menu-list-content" v-show="hover && showMenuChildren">
                  <div>
                    <div class="menu-list-item" v-for="(item, index) in link.children"
                         :key="index"
                         @click.stop="handleMenuClick(link, true, item)">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="height-per-100 d-inline" v-else>
                <v-btn
                    height="100%"
                    light depressed plain
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    class="px-0 ml-16"
                    @click.stop="handleMenuClick(link, true, link)"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                  <span v-if="link.children.length > 0">
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>
                  </span>
                </v-btn>
                <div v-if="link.children.length > 0">
                  <v-fade-transition origin="center center">
                    <div class="menu-children width-per-100" v-show="hover">
                      <div class="menu-divider"><v-divider></v-divider></div>
                      <div class="menu-children-content">
                        <div v-for="(linkChildren,indexA) in link.children" :key="indexA" class="height-per-100"
                             :class="indexA !== 0 ? 'flex-grow-1':'flex-grow-1-dot-3'">
                          <v-divider vertical v-if="indexA !== 0" class="ml-3"></v-divider>
                          <div :class="indexA !== 0 ? 'ml-10':''">
                            <div v-if="linkChildren.title" class="children-title">
                              <span>{{linkChildren.title}}</span>
                            </div>
                            <v-row class="mt-4 ml-0">
                              <v-col class="pa-0 pb-0 mb-5" :cols="indexA !== 0 ? 6 : 4"
                                     v-for="(content,index) in linkChildren.children"
                                     :key="index" style="min-height: 51px;max-height: 51px;">
                                <v-hover v-slot="{ hover }">
                                  <v-row class="mr-0 cursor-pointer d-flex align-center ml-n1"
                                         style="height: 60px;"
                                         @click.stop="handleMenuClick(link,true, content)">
                                    <div class="d-inline width-50 pl-1">
                                      <v-avatar size="50" rounded-2>
                                        <v-img :src="content.icon" width="100%" height="100%" contain></v-img>
                                      </v-avatar>
                                    </div>
                                    <div class="d-inline children-card pl-3">
                                      <v-card color="transparent" flat>
                                        <v-card-title class="pa-0 pt-2" :class="hover ? 'bg-hover':''">{{content.title}}</v-card-title>
                                        <v-card-text class="pa-0 pb-2 mt-1" :class="hover ? 'bg-hover':''" style="line-height: 1.4;">{{content.explain}}</v-card-text>
                                      </v-card>
                                    </div>
                                  </v-row>
                                </v-hover>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-fade-transition>
                </div>
              </div>
            </v-hover>
          </div>
          <div class="d-inline-flex justify-end indexServe" style="width: 100px;" v-if="isToken">
            <v-menu
              rounded="Removed"
              content-class="indexServe-menu"
              offset-y
              nudge-bottom="12"
            >
              <template v-slot:activator="{ attrs, on }">
                <div class="head"  v-bind="attrs" v-on="on">
                  <img 
                    src="https://h5.ophyer.cn/official_website/other/login-touxiang.png" 
                    style="width: 26px;height: 24px;"
                  />
                  <span>{{ $Cookies.get('mobile') }}</span>
                  <v-icon light left dense class="ml-1 mr-0 font-size-12">fas fa-chevron-down</v-icon>

                </div>

              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click.stop="handleIndexServe">
                    进入服务中心
                  </v-list-item-title>
                  <v-list-item-title @click.stop="handleRemove">
                    退出
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="d-inline-flex justify-end" style="width: 100px;" v-else>
            <v-btn
                height="100%"
                light depressed plain
                :ripple="false"
                class="px-0"
                @click.stop="handleRegister"
            >
              <v-hover v-slot="{ hover }">
                <span class="font-size-16" :class="hover ? 'bg-hover':''">注册</span>
              </v-hover>
            </v-btn>
            <div class="height-per-100 py-8 px-4"><v-divider vertical></v-divider></div>
            <v-btn
                height="100%"
                light depressed plain
                :ripple="false"
                class="px-0"
                @click="handleLogin"
            >
              <v-hover v-slot="{ hover }">
                <span class="font-size-16" :class="hover ? 'bg-hover':''">登录</span>
              </v-hover>
            </v-btn>
          </div>
        </div>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
  <v-main v-else :class="$route.path.split('/')[1] === 'wmyH5' ? 'py-0':''">
    <router-view></router-view>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import { browserVersion } from "@/utils/utils"

export default {
  name: 'App',
  data: () => ({
    showHeaderMenu: ["","solutions","case","CaseDetail","InvestmentPromotion","newCase"],
    pathInfo: true,
    currentMenu: "/",
    showMenuChildren: false,
    links: [
      {
        title: "首页",
        router: "/",
        children: []
      },
      // {
      //   title: "产品中心",
      //   router: "",
      //   children: [{
      //     title: "热门推荐",
      //     children: [{
      //       title: "AR创意运营",
      //       icon: require("../../../public/img/icons/menu/arCreativeMarketing.png"),
      //       router: "/ARcreativeMarketing/home",
      //       explain: "一键生成AR趣味游戏",
      //       isNewWindow: true
      //     },{
      //       title: "VR全景网站",
      //       icon: require("../../../public/img/icons/menu/vrPanorama.png"),
      //       router: "/vrPanorama/home",
      //       explain: "掌上体验“身临其境“",
      //       isNewWindow: true
      //     },{
      //       title: "运营导流",
      //       icon: require("../../../public/img/icons/menu/marketingDiversion.png"),
      //       router: "/marketingDiversion/home",
      //       explain: "构建广告新生态",
      //       isNewWindow: true
      //     },
      //     //   {
      //     //   title: "IP授权服务",
      //     //   icon: require("../../../public/img/icons/menu/ipSubStation.png"),
      //     //   router: "/IP/Home",
      //     //   explain: "数字创意，赋能商品品牌",
      //     //   isNewWindow: true
      //     // },
      //       {
      //       title: "电商商城",
      //       icon: require("../../../public/img/icons/menu/onlineRetailersStore.png"),
      //       router: "/OnlineRetailersStore/Home",
      //       explain: "极速打造微商城",
      //       isNewWindow: true
      //     },{
      //       title: "3D场景编辑器",
      //       icon: require("../../../public/img/icons/menu/sceneEditor3D.png"),
      //       router: "/SceneEditor3D/Home",
      //       explain: "轻量易用的跨平台编辑工具",
      //       isNewWindow: true
      //     },{
      //       title: "虚拟直播",
      //       icon: require("../../../public/img/icons/menu/virtualPlay.png"),
      //       router: "",
      //       outerChain: "http://i.wmy.ophyer.com/virtualPlay",
      //       explain: "虚拟人物直播，24小时不断档",
      //       isNewWindow: true
      //     },{
      //       title: "微信公众号助手",
      //       icon: require("../../../public/img/icons/menu/assistantAccount.png"),
      //       router: "/assistantAccount/Home",
      //       explain: "轻松吸粉，养粉，活跃粉丝",
      //       isNewWindow: true
      //     },{
      //       title: "AR打卡",
      //       icon: require("../../../public/img/icons/menu/arPunch.png"),
      //       router: "/arPunch/Home",
      //       explain: "趣味小程序趣味互动游戏",
      //       isNewWindow: true
      //     }]
      //   },{
      //     title: "服务精选",
      //     children: [{
      //       title: "短信服务",
      //       icon: require("../../../public/img/icons/menu/shortMessage.png"),
      //       router: "/shortMessage/Home",
      //       explain: "即时触达全球各地",
      //       isNewWindow: true
      //     },{
      //       title: "大数据分析",
      //       icon: require("../../../public/img/icons/menu/bigDataAnalysis.png"),
      //       router: "/bigDataAnalysis/home",
      //       explain: "清洗杂乱数据，优化数据结构",
      //       isNewWindow: true
      //     },{
      //       title: "大客户定制运营",
      //       icon: require("../../../public/img/icons/menu/customers.png"),
      //       router: "/customers/home",
      //       explain: "活动策划+运营方案+专属运营指导",
      //       isNewWindow: true
      //     },{
      //       title: "AR/VR在线课程",
      //       icon: require("../../../public/img/icons/menu/course.png"),
      //       router: "",
      //       outerChain: "http://edu.ophyer.com/inxedu/front/showcoulist/1",
      //       explain: "教你轻松制作完美内容",
      //       isNewWindow: true
      //     },{
      //       title: "AI智能",
      //       icon: require("../../../public/img/icons/menu/AI.png"),
      //       router: "/AI/AIHome",
      //       explain: "AI+  赋能运营创造力",
      //       isNewWindow: true
      //     },{
      //       title: "视听云",
      //       icon: require("../../../public/img/icons/menu/audiovisualCloud.png"),
      //       router: "/audiovisualCloud/home",
      //       explain: "轻松、安全开展视听业务",
      //       isNewWindow: true
      //     }]
      //   }]
      // },
      // {
      //   title: "解决方案",
      //   router: "/solutions",
      //   renderList: true,
      //   children: [
      //     {
      //       title: "AR互动运营",
      //       router: "/solutions/solutionAR"
      //     },
      //     {
      //       title: "VR全景运营",
      //       router: "/solutions/solutionVR"
      //     }
      //   ]
      // },
      {
        // title: "AR创意运营",
        title: "AR创意互动",
        router: "/solutions/solutionAR",
        children: []
      },
      {
        title: "VR全景网站",
        router: "/solutions/solutionVR",
        children: []
      },
      // {
      //   title: "招商加盟",
      //   router: "/InvestmentPromotion",
      //   children: []
      // }
      // {
      //   title: "精彩案例",
      //   router: "/WonderfulCases",
      //   children: []
      // },
      {
        title: "精品案例",
        router: "/newCase",
        children: []
      },
      {
        title: "派单系统",
        router: "/dispatch",
        children: [],
        isNewWindow: true
      }
    ],
    isToken: false,
    isIE: browserVersion(),
    urlLink: window.location.host === 'wmy.ophyer.com' || window.location.host === 'www.xrealitycloud.cn' ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN
  }),
  watch: {
    $route(to) {
      this.currentMenu = to.path;
      this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;
    }
  },
  computed: {
    ...mapState(["officialWebsite"]),
  },
  created() {
    if(document.hidden !== undefined) {
      document.addEventListener('visibilitychange', () => {
        if(document.hidden == false) {
          this.isToken = this.$Cookies.get('token') ? true:  false
        }
      })
    }
    this.isToken = this.$Cookies.get('token') ? true:  false
  },
  mounted() {
    this.currentMenu = this.$route.path;
    this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split("/")[1]) !== -1;

  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content){
        if(content.outerChain){
          window.open(content.outerChain);
        }else if(content.router){
          if(content.isNewWindow){
            window.open(window.location.protocol + "//" + window.location.host + content.router)
          }else {
            this.$router.push(content.router)
          }
        }
      }
      this.showMenuChildren = false;
    },
    handleRegister: function (){
      window.open(this.urlLink.split(",")[3]+'/register');
    },
    handleLogin(){
      window.open(this.urlLink.split(",")[3]+'/login');
    },
    handleIndexServe: function() {
      window.open(this.urlLink.split(",")[3]);
    },
    handleRemove() {
      var keys = document.cookie.match(/[^ =;]+(?==)/g)
      let domain = window.location.host === 'www.xrealitycloud.cn' ? '.ophyer.com': '.igame123.com'
      if (keys) {
        for (var i = keys.length; i--;) {
          // document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
          // document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
          document.cookie = keys[i] + '=0;path=/;domain='+domain+';expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
        }
      }

      this.$Cookies.remove('token')
      this.isToken = false
      this.$Cookies.remove('mobile')

    }
  }
};
</script>

<style lang="scss" scoped>
.isIE{
  width: 1200px;
  margin: 0 auto;
  margin-left: -20px !important;
}
::v-deep .app-bar{
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  z-index: 1000 !important;
  hr {
    border-color: #333333;
    opacity: 0.2;
  }
  .v-toolbar__content {
    padding: 0;
    .logo-btn{
      // margin-left: -32px;
      .v-btn__content{
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu{
      margin: 0 auto;
      max-width: 1200px;
      .v-btn:not(.v-btn--round).v-size--default{
        min-width: unset;
      }
      .v-btn__content {
        opacity: unset !important;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title{
      font-size: 16px;
      height: 30px;
      position: relative;
      .menu-btn-active{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #0568FD;
        //font-family: $bold-font-family;
      }
    }
    .menu-divider{
      width: 100%;
      position: fixed;
      top: 61px;
      left: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      hr {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
    .menu-children{
      height: 320px;
      width: 100%;
      position: fixed;
      top: 60px;
      left: 0;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      padding: 40px 0 30px 0;
      box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1);
      //font-family: SourceHanSansCN-Normal,sans-serif;
      .menu-children-content{
        min-width: 1024px;
        max-width: 1200px;
        width: 100%;
        display: inline-flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        hr {
          position: absolute;
          top: 0;
        }
        .children-title {
          font-size: 16px;
          height: 16px;
          //font-family: $normal-font-family;
        }
        .children-card {
          width: calc(100% - 50px);
          .v-card__title{
            font-size: 16px;
          }
          .v-card__text{
            font-size: 10px;
          }
        }
      }
    }
  }
  .menu-list{
    position: relative;
    .menu-list-content{
      position: absolute;
      top: 60px;
      left: 20px;
      width: 160px;
      height: 120px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #FFFFFF;
      box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      .menu-list-item {
        width: 100%;
        padding: 14px;
        cursor: pointer;
      }
      .menu-list-item:hover {
        color: #0568FD;
      }
    }
  }
}

.indexServe {
  margin: 12px 0;
  // button {
  //   display: inline-block;
  //   vertical-align: middle;
  //   width: 112px;
  //   height: 36px !important;
  //   border: 1px solid #0076fe;
  //   text-align: center;
  //   background-color: #0076fe;
  //   border-radius: 28px;
  //   box-sizing: border-box;
  //   color: #fff !important;
  //   font-size: 14px;
  //   ::v-deep .v-btn__content {
  //     margin-top: -2px !important;
  //   }
  // }
  .head {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
}

.indexServe-menu {
  width: 200px;
  .v-list-item {
    display: inline-block !important;
    width: 100%;
    padding: 0;
    .v-list-item__title {
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 16px;
      &:hover {
        background: #eaf3ff;
        color: #59a0ff;
      }
    }
    &::before {
      display: none;
    }
  }
}
</style>
